import {v4 as uuidv4} from "uuid";

export default function getProducts(room, austattung_produkt, selectedAlternatives, existingActions){

    const productList = [];
    let actions = [];

    function getNewUuid() {
        const uuid = uuidv4();
        let found = actions.filter(i => i.uuid === uuid)[0] !== undefined;
        if (found) { return getNewUuid(); }
        found = existingActions.filter(i => i.uuid === uuid)[0] !== undefined;
        if (found) { return getNewUuid(); }
        return uuid;
    }
    function addActionForDevice(device, item, nr) {
        const retList = [];
        for (let i = 0; i < nr; i++) {
            switch (item.key) {
                case "schaltbare_beleuchtung": //switch_light
                    const nAction = {type: 'switch_light', uuid: getNewUuid(), device, addedIn: 'AddTaster'};
                    actions.push(nAction); retList.push(nAction);
                    break;
                case "schaltbare_steckdosen": //switch_powerplug
                    const nActionp = {type: 'switch_powerplug', uuid: getNewUuid(), device, addedIn: 'AddTaster'};
                    actions.push(nActionp); retList.push(nActionp);
                    break;
                case "dimmbare_beleuchtung": //dim
                    const nActiond = {type: 'dim', uuid: getNewUuid(), device, addedIn: 'AddTaster'};
                    actions.push(nActiond); retList.push(nActiond);
                    break;
                case "verschattung_steuern": //shading_shutter
                case "rolllaeden_steuern":
                case "jalousie_steuern":
                    const nActionj = {type: 'shading_shutter', uuid: getNewUuid(), device, addedIn: 'AddTaster'};
                    actions.push(nActionj); retList.push(nActionj);
                    break;
                case "heizen_heizkoerper":
                case "heizen_fußbodenheizung":
                    const nActionh = {type: 'shading_shutter', uuid: getNewUuid(), device, addedIn: 'AddTaster'};
                    actions.push(nActionh); retList.push(nActionh);
                    break;
                default: break;
            }
        }
        return retList;
    }

    const checkList = [
        {key: "schaltbare_beleuchtung", onlyFirst: false, matrix: "schalten"},
        {key: "dimmbare_beleuchtung", onlyFirst: false, matrix: "dimmen"},
        {key: "heizen_fußbodenheizung", onlyFirst: true, matrix: "heizen"},
        {key: "heizen_heizkoerper", onlyFirst: true, matrix: "heizen"},
        {key: "verschattung_steuern", onlyFirst: true, matrix: "verschatten"},
        {key: "rolllaeden_steuern", onlyFirst: true, matrix: "verschatten"},
        {key: "jalousie_steuern", onlyFirst: true, matrix: "verschatten"}
    ];
    const matrix = {
        "schalten": [
            {doorCount: 1, counter: 1, items: [{key: "506U", count: 1,}]},
            {doorCount: 1, counter: 2, items: [{key: "505EU", count: 1}]},
            {doorCount: 1, counter: 3, items: [{key: "505EU", count: 1}, {key: "506U", count: 1}]},
            {doorCount: 1, counter: 4, items: [{key: "505EU", count: 2}]},
            {doorCount: 1, counter: 5, items: [{key: "505EU", count: 2}, {key: "506U", count: 1}]},
            {doorCount: 1, counter: 6, items: [{key: "505EU", count: 3}]},
            {doorCount: 2, counter: 1, items: [{key: "506U", count: 2}]},
            {doorCount: 2, counter: 2, items: [{key: "509U", count: 2}]},
            {doorCount: 2, counter: 3, items: [{key: "506U", count: 2}, {key: "509U", count: 2}]},
            {doorCount: 2, counter: 4, items: [{key: "509U", count: 4}]},
            {doorCount: 2, counter: 5, items: [{key: "509U", count: 10}]},
            {doorCount: 2, counter: 6, items: [{key: "509U", count: 6}]},
            {doorCount: 3, counter: 1, items: [{key: "506U", count: 3}]},
            {doorCount: 3, counter: 2, items: [{key: "509U", count: 3}]},
            {doorCount: 3, counter: 3, items: [{key: "506U", count: 6}, {key: "507U", count: 3}]},
            {doorCount: 3, counter: 4, items: [{key: "507U", count: 4}, {key: "506U", count: 8}]},
            {doorCount: 3, counter: 5, items: [{key: "507U", count: 5}, {key: "506U", count: 10}]},
            {doorCount: 3, counter: 6, items: [{key: "507U", count: 6}, {key: "506U", count: 12}]},
            {doorCount: 4, counter: 1, items: [{key: "506U", count: 4}]},
            {doorCount: 4, counter: 2, items: [{key: "506U", count: 8}]},
            {doorCount: 4, counter: 3, items: [{key: "506U", count: 6}, {key: "507U", count: 6}]},
            {doorCount: 4, counter: 4, items: [{key: "507U", count: 8}, {key: "506U", count: 8}]},
            {doorCount: 4, counter: 5, items: [{key: "507U", count: 10}, {key: "506U", count: 10}]},
            {doorCount: 4, counter: 6, items: [{key: "507U", count: 12}, {key: "506U", count: 12}]},
            {doorCount: 5, counter: 1, items: [{key: "506U", count: 5}]},
            {doorCount: 5, counter: 2, items: [{key: "506U", count: 10}]},
            {doorCount: 5, counter: 3, items: [{key: "506U", count: 6}, {key: "507U", count: 9}]},
            {doorCount: 5, counter: 4, items: [{key: "507U", count: 12}, {key: "506U", count: 8}]},
            {doorCount: 5, counter: 5, items: [{key: "507U", count: 15}, {key: "506U", count: 10}]},
            {doorCount: 5, counter: 6, items: [{key: "507U", count: 18}, {key: "506U", count: 12}]},
        ],
        "dimmen": [
            {doorCount: 1, counter: 1, items: [{key: "1710DE", count: 1}]},
            {doorCount: 1, counter: 2, items: [{key: "1710DE", count: 2}]},
            {doorCount: 1, counter: 3, items: [{key: "1710DE", count: 3}]},
            {doorCount: 1, counter: 4, items: [{key: "1710DE", count: 4}]},
            {doorCount: 1, counter: 5, items: [{key: "1710DE", count: 5}]},
            {doorCount: 1, counter: 6, items: [{key: "1710DE", count: 6}]},
            {doorCount: 2, counter: 1, items: [{key: "1711DE", count: 1}, {key: "1723NE", count: 1}]},
            {doorCount: 2, counter: 2, items: [{key: "1711DE", count: 2}, {key: "1723NE", count: 2}]},
            {doorCount: 2, counter: 3, items: [{key: "1711DE", count: 3}, {key: "1723NE", count: 3}]},
            {doorCount: 2, counter: 4, items: [{key: "1711DE", count: 4}, {key: "1723NE", count: 4}]},
            {doorCount: 2, counter: 5, items: [{key: "1711DE", count: 5}, {key: "1723NE", count: 5}]},
            {doorCount: 2, counter: 6, items: [{key: "1711DE", count: 6}, {key: "1723NE", count: 6}]},
            {doorCount: 3, counter: 1, items: [{key: "1711DE", count: 1}, {key: "1723NE", count: 2}]},
            {doorCount: 3, counter: 2, items: [{key: "1711DE", count: 2}, {key: "1723NE", count: 4}]},
            {doorCount: 3, counter: 3, items: [{key: "1711DE", count: 3}, {key: "1723NE", count: 6}]},
            {doorCount: 3, counter: 4, items: [{key: "1711DE", count: 4}, {key: "1723NE", count: 8}]},
            {doorCount: 3, counter: 5, items: [{key: "1711DE", count: 5}, {key: "1723NE", count: 10}]},
            {doorCount: 3, counter: 6, items: [{key: "1711DE", count: 6}, {key: "1723NE", count: 12}]},
            {doorCount: 4, counter: 1, items: [{key: "1711DE", count: 1}, {key: "1723NE", count: 3}]},
            {doorCount: 4, counter: 2, items: [{key: "1711DE", count: 2}, {key: "1723NE", count: 6}]},
            {doorCount: 4, counter: 3, items: [{key: "1711DE", count: 3}, {key: "1723NE", count: 9}]},
            {doorCount: 4, counter: 4, items: [{key: "1711DE", count: 4}, {key: "1723NE", count: 12}]},
            {doorCount: 4, counter: 5, items: [{key: "1711DE", count: 5}, {key: "1723NE", count: 15}]},
            {doorCount: 4, counter: 6, items: [{key: "1711DE", count: 6}, {key: "1723NE", count: 18}]},
            {doorCount: 5, counter: 1, items: [{key: "1711DE", count: 1}, {key: "1723NE", count: 4}]},
            {doorCount: 5, counter: 2, items: [{key: "1711DE", count: 2}, {key: "1723NE", count: 8}]},
            {doorCount: 5, counter: 3, items: [{key: "1711DE", count: 3}, {key: "1723NE", count: 12}]},
            {doorCount: 5, counter: 4, items: [{key: "1711DE", count: 4}, {key: "1723NE", count: 16}]},
            {doorCount: 5, counter: 5, items: [{key: "1711DE", count: 6}, {key: "1723NE", count: 20}]},
            {doorCount: 5, counter: 6, items: [{key: "1711DE", count: 6}, {key: "1723NE", count: 24}]},
        ],
        "verschatten": [
            {doorCount: 1, counter: 1, items: [{key: "509VU", count: 1}]},
            {doorCount: 1, counter: 2, items: [{key: "509VU", count: 2}]},
            {doorCount: 1, counter: 3, items: [{key: "509VU", count: 3}]},
            {doorCount: 1, counter: 4, items: [{key: "509VU", count: 4}]},
            {doorCount: 1, counter: 5, items: [{key: "509VU", count: 5}]},
            {doorCount: 1, counter: 6, items: [{key: "509VU", count: 6}]},
            {doorCount: 2, counter: 1, items: [{key: "509VU", count: 1}]},
            {doorCount: 2, counter: 2, items: [{key: "509VU", count: 2}]},
            {doorCount: 2, counter: 3, items: [{key: "509VU", count: 3}]},
            {doorCount: 2, counter: 4, items: [{key: "509VU", count: 4}]},
            {doorCount: 2, counter: 5, items: [{key: "509VU", count: 5}]},
            {doorCount: 2, counter: 6, items: [{key: "509VU", count: 6}]},
            {doorCount: 3, counter: 1, items: [{key: "509VU", count: 1}]},
            {doorCount: 3, counter: 2, items: [{key: "509VU", count: 2}]},
            {doorCount: 3, counter: 3, items: [{key: "509VU", count: 3}]},
            {doorCount: 3, counter: 4, items: [{key: "509VU", count: 4}]},
            {doorCount: 3, counter: 5, items: [{key: "509VU", count: 5}]},
            {doorCount: 3, counter: 6, items: [{key: "509VU", count: 6}]},
            {doorCount: 4, counter: 1, items: [{key: "509VU", count: 1}]},
            {doorCount: 4, counter: 2, items: [{key: "509VU", count: 2}]},
            {doorCount: 4, counter: 3, items: [{key: "509VU", count: 3}]},
            {doorCount: 4, counter: 4, items: [{key: "509VU", count: 4}]},
            {doorCount: 4, counter: 5, items: [{key: "509VU", count: 5}]},
            {doorCount: 4, counter: 6, items: [{key: "509VU", count: 6}]},
            {doorCount: 5, counter: 1, items: [{key: "509VU", count: 1}]},
            {doorCount: 5, counter: 2, items: [{key: "509VU", count: 2}]},
            {doorCount: 5, counter: 3, items: [{key: "509VU", count: 3}]},
            {doorCount: 5, counter: 4, items: [{key: "509VU", count: 4}]},
            {doorCount: 5, counter: 5, items: [{key: "509VU", count: 5}]},
            {doorCount: 5, counter: 6, items: [{key: "509VU", count: 6}]},
        ],
        "heizen": [
            {doorCount: 1, counter: 1, items: [{key: "rt-regler", count: 1}]},
            {doorCount: 1, counter: 2, items: [{key: "rt-regler", count: 2}]},
            {doorCount: 1, counter: 3, items: [{key: "rt-regler", count: 3}]},
            {doorCount: 1, counter: 4, items: [{key: "rt-regler", count: 4}]},
            {doorCount: 1, counter: 5, items: [{key: "rt-regler", count: 5}]},
            {doorCount: 1, counter: 6, items: [{key: "rt-regler", count: 6}]},
            {doorCount: 2, counter: 1, items: [{key: "rt-regler", count: 1}]},
            {doorCount: 2, counter: 2, items: [{key: "rt-regler", count: 2}]},
            {doorCount: 2, counter: 3, items: [{key: "rt-regler", count: 3}]},
            {doorCount: 2, counter: 4, items: [{key: "rt-regler", count: 4}]},
            {doorCount: 2, counter: 5, items: [{key: "rt-regler", count: 5}]},
            {doorCount: 2, counter: 6, items: [{key: "rt-regler", count: 6}]},
            {doorCount: 3, counter: 1, items: [{key: "rt-regler", count: 1}]},
            {doorCount: 3, counter: 2, items: [{key: "rt-regler", count: 2}]},
            {doorCount: 3, counter: 3, items: [{key: "rt-regler", count: 3}]},
            {doorCount: 3, counter: 4, items: [{key: "rt-regler", count: 4}]},
            {doorCount: 3, counter: 5, items: [{key: "rt-regler", count: 5}]},
            {doorCount: 3, counter: 6, items: [{key: "rt-regler", count: 6}]},
            {doorCount: 4, counter: 1, items: [{key: "rt-regler", count: 1}]},
            {doorCount: 4, counter: 2, items: [{key: "rt-regler", count: 2}]},
            {doorCount: 4, counter: 3, items: [{key: "rt-regler", count: 3}]},
            {doorCount: 4, counter: 4, items: [{key: "rt-regler", count: 4}]},
            {doorCount: 4, counter: 5, items: [{key: "rt-regler", count: 5}]},
            {doorCount: 4, counter: 6, items: [{key: "rt-regler", count: 6}]},
            {doorCount: 5, counter: 1, items: [{key: "rt-regler", count: 1}]},
            {doorCount: 5, counter: 2, items: [{key: "rt-regler", count: 2}]},
            {doorCount: 5, counter: 3, items: [{key: "rt-regler", count: 3}]},
            {doorCount: 5, counter: 4, items: [{key: "rt-regler", count: 4}]},
            {doorCount: 5, counter: 5, items: [{key: "rt-regler", count: 5}]},
            {doorCount: 5, counter: 6, items: [{key: "rt-regler", count: 6}]},
        ]
    };
    const produkte = {
        "506U": ["wechsel-1", "wechsel-1-w"],
        "507U": ["kreuz-1", "kreuz-1-w"],
        "509U": ["doppel-wechsel-1", "doppel-wechsel-1-w"],
        "505EU": ["serien", "serien-w"],
        "509VU": ["verschatt-1", "verschatt-1-w"],
        "rt-regler": ["rt-regler", "rt-ventil-1", "rt-abdeckung", "rt-regler-rahmen"],
        "1710DE": ["tastdimmer-1", "tastdimmer-1-w"],
        "1711DE": ["tastdimmer-2", "tastdimmer-2-w"],
        "1723NE": ["tastdimmer-3", "tastdimmer-3-w"],
    };
    console.log('austattung_product', austattung_produkt)
    let requiredFrames = 0;
    console.log('checklist', checkList)
    checkList.forEach(item => {
        if(room.selectedEquipment[item.key] !== undefined && room.selectedEquipment[item.key] > 0){
            const mItems = matrix[item.matrix].filter(i => i.doorCount === room.doorCount);
            const max = matrix[item.matrix].filter(i => i.doorCount === room.doorCount && i.counter === 6)[0];
            let required = room.selectedEquipment[item.key]; let requiredTermostat = required;
            if (item.matrix === 'verschatten') { required = room.windowCount; }
            if (item.matrix === 'heizen') {
                requiredTermostat = 0;
                if (room.selectedEquipment[item.key] > 0) { requiredTermostat += 1; }
                required = item.key === "heizen_heizkoerper" ? room.heatingCount : room.floorheatingCount;
            }
            const c = Math.floor(required / 6);
            for(let i = 0; i < c; i++) {
                // eslint-disable-next-line
                max.items.forEach(mItem => {
                    produkte[mItem.key].forEach((p, pIdx) => {
                        if (pIdx < 1) {
                            const dActions = addActionForDevice(austattung_produkt[p], item, mItem.count);
                            productList.push({
                                product: austattung_produkt[p],
                                count: item.matrix === 'heizen' ? requiredTermostat : mItem.count,
                                actions: dActions.map(i => i.uuid)
                            });
                        } else {
                            productList.push({
                                product: austattung_produkt[p],
                                count: mItem.count,
                            });
                        }
                    });
                    requiredFrames += item.matrix !== 'heizen' ? mItem.count : 0;
                });
            }
            required -= c * 6;
            if (c > 0 && item.matrix === "heizen") { requiredTermostat = 0; }
            const toPush = mItems.filter(i => i.counter === required)[0];
            if (toPush) {
                toPush.items.forEach(mItem => {
                    produkte[mItem.key].forEach((p, pIdx) => {
                        if (pIdx < 1) {
                            const dActions = addActionForDevice(austattung_produkt[p], item, mItem.count);
                            productList.push({
                                product: austattung_produkt[p],
                                count: item.matrix === 'heizen' ? requiredTermostat : mItem.count,
                                actions: dActions.map(i => i.uuid)
                            });
                            // if (item.matrix === 'heizen') {
                            //     productList.push({
                            //         product: austattung_produkt["rt-regler-rahmen"],
                            //         count: requiredTermostat,
                            //     });
                            //     console.log('added rahmen', austattung_produkt['rt-regler-rahmen'], requiredTermostat)
                            // }
                        } else {
                            productList.push({
                                product: austattung_produkt[p],
                                count: mItem.count,
                            });
                        }
                    });
                    requiredFrames += item.matrix !== 'heizen' ? mItem.count : 0;
                });
            }
        }
    });

    const frames = [
        {key: "r-5f", counter: 5},
        {key: "r-4f", counter: 4},
        {key: "r-3f", counter: 3},
        {key: "r-2f", counter: 2},
        {key: "r-1f", counter: 1},
    ];
    frames.forEach(frame => {
        const c = Math.floor(requiredFrames / room.doorCount / frame.counter);
        if (c > 0) {
            requiredFrames -= c * frame.counter * room.doorCount;
            productList.push({
                product: austattung_produkt[frame.key],
                count: c * room.doorCount
            });
        }
    });
console.log('addtaster returning', productList, actions)
    return {list: productList, actions};
}

