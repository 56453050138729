import React from "react"
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MenuIcon from '@material-ui/icons/Menu';
import "./LabelBox.scss";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function LabelBox({value, setValue, deleteItem, isBold= false, editAble = true}){

    const [edit, setEdit] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const isIframe = window.parentIFrame !== undefined;
    const [iframeRoomName, setIframeRoomName] = React.useState('');

    function onKeyPress(e){
        if(e.charCode === 13){
            if (isIframe) { setValue(iframeRoomName); }
            setEdit(false);
        }
    }
    function onChange(e) {
        setIframeRoomName(e.target.value);
    }
    function onBlur(e) {
        setValue(iframeRoomName);
        setEdit(false);
    }
    function getLabelOrInput(){
        if(edit){
            return isIframe
                ? <input type="text" name="inputLabel"
                         value={iframeRoomName} autoFocus={true} onKeyPress={onKeyPress}
                         onChange={onChange}
                         onBlur={onBlur}
                />
                : <input type="text" name="inputLabel"
                          onChange={e => setValue(e.target.value)}
                          onBlur={e => setEdit(false)}
                          value={value}
                          className="inputLabel"
                          autoFocus={true}
                          onKeyPress={onKeyPress}
            />;
        }else{
            return <div className={"labeltext" + (isBold ? " bold" : "")} dangerouslySetInnerHTML={{__html: value}}></div>
        }
    }
    function handleDeleteDialogOpen() {
        setDeleteDialogOpen(true);
    }
    function handleDeleteDialogClose(){
        setDeleteDialogOpen(false);
    }
    function handleDeleteDialogAgree(e){
        deleteItem(e);
    }

    function generateDeleteButton(){
           return <>
                <DeleteForeverIcon style={{ fontSize: "14px", padding: "2px", verticalAlign: "sub"}} onClick={handleDeleteDialogOpen}/>
                <Dialog
                    open={deleteDialogOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleDeleteDialogClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description" color={"inherit"}>
                            Wollen Sie '{value}' wirklich löschen?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{justifyContent: "center"}}>
                        <div onClick={handleDeleteDialogClose} color="primary" className={"modalButton disagree"}>
                            Abbrechen
                        </div>
                        <div onClick={handleDeleteDialogAgree} color="primary" className={"modalButton agree"}>
                            Löschen
                        </div>
                    </DialogActions>
                </Dialog>
            </>
    }

    return <div className="displayInlineBlock labelBox">
        <div className="dragHandle">
            {!edit && editAble ? <MenuIcon style={{ fontSize: "14px", padding: "2px", verticalAlign: "sub"}} /> : null }
        </div>
        {getLabelOrInput()}
        <div className="editHandle">
            {!edit && editAble ? <EditIcon style={{ fontSize: "14px", padding: "2px", verticalAlign: "sub"}} onClick={e => {setEdit(true);setIframeRoomName(value);}}/>: null }
        </div>
        <div className="deleteHandle">
            {!edit && editAble ? generateDeleteButton() : null }
        </div>
    </div>;
}
