import ProjectSelectComponent from "./ProjectSelectComponent";
import FloorComponent from "./FloorComponent";
import AddFloorComponent from "./AddFloorComponent";
import React, {useEffect} from "react";
import iconFenster from "./../images/fenster.svg"
import iconTuer from "./../images/schalterhand.svg"
import iconHeizung from "./../images/heizkoerper.svg"
import iconFussbodenHeizung from "./../images/fussbodenheizung.svg"
import startSmart from "./../images/header_smart.jpg"
import startKonventionell from "./../images/header_konventionell.jpg"
import startHome from "./../images/header_home.jpg"
import {ReactSortable} from "react-sortablejs";
import {v4 as uuidv4} from "uuid";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import iIconActive from "../images/info_icon_active.svg";
import iIconBlue from "../images/info_icon_blue.svg";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import FilterIcon from "@material-ui/icons/Tune";
import {ChevronRight} from "@material-ui/icons";
import {ChevronLeft} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import Popover from '@material-ui/core/Popover';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// import home_produkt from "../data/home_austattung_produkt.json";
// import all_produkt from "../data/products.json";
import {useTranslation} from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function StepOneComponent({project, projectSelectList, setSelectedProject, updateProject, schalterdesign, startProject, setProjectCode, projectCode, loadProjectFromCode, trackingObj, isIFrame, jumpTo, setPrCode, initproject}){

    const [dragActive, setDragActive] = React.useState(false);
    const [customOpened, setCustomOpened] = React.useState(false);
    const [currentColumn, setCurrentColumn] = React.useState(1);
    const {t, i18n} = useTranslation();
    const language = i18n.resolvedLanguage;
    const isdev = window.location.host.indexOf('dev.p14') >= 0 || window.location.host.indexOf('localhost') >= 0;

    const [projectName, setProjectName] = React.useState(project.projectName);
    const [prNameBlured, setPrNameBlured] = React.useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        // if (!isIFrame) { setTimeout(() => {document.body.style.overflow = 'unset'; }, 200); }
    }, []);
    if (isIFrame) {
        if (prNameBlured) {
            // window.focus();
            // Remove focus from any focused element
            if (document.activeElement) {
                document.activeElement.blur();
            }
            const el = document.getElementById('projectName');
            if (el) {
                el.setAttribute('disabled', 'disabled');
                setTimeout(() => { el.removeAttribute('disabled'); }, 150);
            }
        }
    }
    function onStart(){
        setDragActive(true)
    }
    function onEnd(){
        setDragActive(false)
    }

    function onAddFloor(floor){
        const newProject = {
            ...project,
            floors: [
                ...project.floors,
                {...floor, id: uuidv4()}
            ]
        };
        updateProject(newProject);
    }
    function ChangeProjectName(e){
        setProjectName(e.target.value);
        setPrNameBlured(false);
    }
    function onProjectNameBlur(e) {
        if (prNameBlured) { return true; }
        setPrNameBlured(true);
        updateProject({...project, projectName: projectName});
        setPrCode(null);
        // return null;
    }
    function setFloors(floors){
        updateProject({...project, floors: {...floors}});
    }
    function updateFloor(floor){
        updateProject({...project, floors: project.floors.map(f => f.id !== floor.id ? f : {...floor})});
    }
    function deleteFloor(floor){
        updateProject({...project, floors: project.floors.filter(f => f.id !== floor.id)});
    }
    function getFloorTotal(cat) {
        let total = 0;
        project.floors.map(f => f.rooms.map(r => total += r[cat]));
        return total;
    }
    const ColorButton4 = withStyles((theme) => ({
        root: {
            color: "var(--blueColor)",
            backgroundColor: "var(--weiss)",
            '&:hover': {
                backgroundColor:"var(--blueColor)",
                color: "var(--weiss)",
            },
            cursor: "pointer",
            alignContent: "center",
            height: "10px",
            padding: "23px",
            fontSize: "16px",
            marginTop: "0",
            marginRight: "2%",
            marginLeft: "2%",
            textAlign: "center",
            borderRadius: "0",
            textTransform: "inherit",
            width: "46%",
            border: "2px solid transparent",
        },
    }))(Button);
    const ColorButton4active = withStyles((theme) => ({
        root: {
            color: "var(--weiss)",
            backgroundColor: "var(--blueColor)",
            '&:hover': {
                backgroundColor:"var(--blueColor)",
                color: "var(--weiss)",
            },
            cursor: "pointer",
            height: "10px",
            alignContent: "center",
            padding: "25px",
            fontSize: "16px",
            marginTop: "0",
            marginRight: "2%",
            marginLeft: "2%",
            textAlign: "center",
            borderRadius: "0",
            textTransform: "inherit",
            width: "46%",
        },
    }))(Button);
    function TooltipWithButton(props) {
        const el = document.createElement('a');
        document.body.appendChild(el);
        const [anchorEl, setAnchorEl] = React.useState(null);
        const [opened, setOpened] = React.useState(false);
        const id = 'popover_' + ((Math.random() * 1000) + 1);
        // const open = Boolean(anchorEl);
        const params = props.props;
        // setTimeout(() => {if (!opened) {document.body.style.overflow = 'unset';}}, 100);
        const handleClick = (event) => {
            event.preventDefault(); event.stopPropagation();
            setAnchorEl(event.currentTarget); setOpened(true);
            // document.body.style.overflow = 'hidden';
            return false;
        };
        const handleClose = (event) => {
            event.preventDefault(); event.stopPropagation();
            setOpened(false);
            // document.body.style.overflow = 'unset';
            return false;
        };
        return <>
            <a aria-describedby={id} variant="contained" onClick={handleClick} className={"button-without-style" + (!params.active ? (!params.noActive ? ' inactive' : '') : '') + (params.absolutePosition ? ' absolutePosition' : '')}>
                {params.active
                    ? <span className="info-icon">
                        <img src={iIconActive} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"paramActive"}/>
                    </span>
                    : (!params.noActive
                        ? <span className="info-icon">
                            <img src={iIconBlue} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"hideOnHover"}/>
                            <img src={iIconActive} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"showOnHover"}/>
                        </span>
                        : <span>
                            <img src={iIconBlue} width="32" height="32" style={{marginTop: "0px"}} alt={"info"} className={"paramNoActive"}/>
                        </span>
                    )
                }
            </a>
            {'parentIFrame' in window
            ? <Popover id={id} open={true} anchorEl={anchorEl} onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                style={{maxWidth: window.innerWidth > 800 ? '75%' : '100%', visibility: opened ? 'visible': 'hidden'}}
            >
                <div className={"popoverContainer"} style={{color: 'var(--blueColor)', padding: '15px', position: 'relative'}}>
                    <a href={"/"} onClick={handleClose} style={{position: 'absolute', top: '5px', right: '5px', color: 'var(--blueColor)'}}><CloseIcon /></a>
                    <p style={{fontWeight: 'bold'}}>{params.title}</p>
                    <p dangerouslySetInnerHTML={{__html: params.message}}></p>
                </div>
            </Popover>
            : <Popover id={id} open={opened} anchorEl={anchorEl} onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                style={{maxWidth: window.innerWidth > 800 ? '75%' : '100%', visibility: opened ? 'visible': 'hidden'}}
            >
                <div className={"popoverContainer"} style={{color: 'var(--blueColor)', padding: '15px', position: 'relative'}}>
                    <a href={"/"} onClick={handleClose} style={{position: 'absolute', top: '5px', right: '5px', color: 'var(--blueColor)'}}><CloseIcon /></a>
                    <p style={{fontWeight: 'bold'}}>{params.title}</p>
                    <p dangerouslySetInnerHTML={{__html: params.message}}></p>
                </div>
            </Popover>}
        </>;
    }

    function getButton4(text, type, hoverText1, hoverText2){
        if(project.subProjectType === type) {
            return <ColorButton4active onClick={e => startProject(project.projectType, type, true, false)}>
                <span className={"headerMenuItem"} style={{width: '100%'}}><strong style={{flexGrow: '1'}}>{text}</strong>
                <TooltipWithButton props={{title: hoverText1, message: hoverText2, active: true}} />
                </span>
            </ColorButton4active>
        }
        return <ColorButton4 onClick={e => startProject(project.projectType, type, true, false)} className={"system-select-button"}>
            <span className={"headerMenuItem"} style={{width: '100%'}}><strong style={{flexGrow: '1'}}>{text}</strong>
                <TooltipWithButton props={{title: hoverText1, message: hoverText2}} />
            </span>
        </ColorButton4>
    }
    function getButton5(text, type, hoverText1, hoverText2){
        if(project.subProjectType === type) {
            return <ColorButton4active onClick={e => startProject(project.projectType, type, true, false)} className={" phone-multiline"}>
                <span className={"headerMenuItem"} style={{width: '100%'}}><strong style={{flexGrow: '1'}}>{text}</strong></span>
                <TooltipWithButton props={{title: hoverText1, message: hoverText2, active: true}} />
            </ColorButton4active>
        }
        return <ColorButton4 onClick={e => startProject(project.projectType, type, true, false)} className={"system-select-button phone-multiline"}>
            <span className={"headerMenuItem"} style={{width: '100%'}}><strong style={{flexGrow: '1'}}>{text}</strong></span>
            <TooltipWithButton props={{title: hoverText1, message: hoverText2}} />
        </ColorButton4>
    }
    const StartButton = withStyles((theme) => ({
        root: {
            color: "var(--textColor)",
            backgroundColor: "var(--weiss)",
            '&:hover': {
                backgroundColor:"var(--blueColor)",
                color: "var(--weiss)"
            },
            '&.active': {
                backgroundColor:"var(--blueColor)",
                color: "var(--weiss)"
            },
            cursor: "pointer",
            height: "63px",
            padding: "21px 30px",
            fontSize: "22px",
            margin: "0",
            textAlign: "center",
            borderRadius: "0",
            textTransform: "inherit",
            width: "auto",
        },
    }))(Button);
    function setNextColumn() {
        if (currentColumn + 1 > 4) { setCurrentColumn(1); }
        else { setCurrentColumn(currentColumn + 1); }
    }
    function setPreviousColumn() {
        if (currentColumn - 1 < 1) { setCurrentColumn(4); }
        else { setCurrentColumn(currentColumn - 1); }
    }
    function GetPlanColumns(props) {
        return <>
            <div className={"text-center col-2 w-188" + (currentColumn === 1 ? ' active' : ' hidden')}>
                <img src={iconTuer} alt="" style={{maxWidth: "100%", maxHeight: "100%", height: "42px"}}/>
                {['konventionell', 'home'].includes(project.projectType)
                    ? <TooltipWithButton props={{absolutePosition: true, noActive: true, title: '', message: t('project.structure.installationLocation.' + project.projectType + '.info')}} />
                    : null}
                {project.projectType === 'knx'
                    ? <TooltipWithButton props={{absolutePosition: true, noActive: true, title: '', message: t('project.structure.installationLocation.' + project.projectType + '.info')}} />
                    : null}
                <div className={"roomCounter"}>{getFloorTotal('doorCount')}</div>
                <div className="svgDescription"
                     style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>{t('project.structure.installationLocation')}</div>
                </div>
            </div>
            <div className={"text-center col-3 w-188" + (currentColumn === 2 ? ' active' : ' hidden')}>
                <img src={iconFenster} alt="" style={{maxWidth: "100%", maxHeight: "100%", height: "42px"}}/>
                {['nl'].includes(language)
                    ? <TooltipWithButton props={{absolutePosition: true, noActive: true, title: '', message: t('project.structure.window.info')}} />
                    : null}
                <div className={"roomCounter"}>{getFloorTotal('windowCount')}</div>
                <div className="svgDescription">{t('project.structure.window')}</div>
            </div>
            <div className={"text-center col-3 w-188" + (currentColumn === 3 ? ' active' : ' hidden')}>
                <img src={iconHeizung} alt="" style={{maxWidth: "100%", maxHeight: "100%", height: "42px"}}/>
                {['nl'].includes(language)
                    ? <TooltipWithButton props={{absolutePosition: true, noActive: true, title: '', message: t('project.structure.radiator.info')}} />
                    : null}
                <div className={"roomCounter"}>{getFloorTotal('heatingCount')}</div>
                <div className="svgDescription">{t('project.structure.radiator')}</div>
            </div>
            <div className={"text-center col-3 w-188" + (currentColumn === 4 ? ' active' : ' hidden')}>
                <img src={iconFussbodenHeizung} alt="" style={{maxWidth: "100%", maxHeight: "100%", height: "42px"}}/>
                {['nl'].includes(language)
                    ? <TooltipWithButton props={{absolutePosition: true, noActive: true, title: '', message: t('project.structure.underfloorHeating.info')}} />
                    : null}
                <div className={"roomCounter"}>{getFloorTotal('floorheatingCount')}</div>
                <div className="svgDescription">{t('project.structure.underfloorHeating')}</div>
            </div>
            {!props.woArrow
                ? <>
                    <a style={{color: "inherit"}} href={"/"} onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setNextColumn();
                        return false;
                    }} className={"next-button"}>
                        <ChevronRight/>
                    </a>
                    <a style={{color: "inherit"}} href={"/"} onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setPreviousColumn();
                        return false;
                    }} className={"previous-button"}>
                        <ChevronLeft/>
                    </a>
                </>
                : null
            }
        </>;
    }
    function ChangeLanguageAndRoute(lang = 'de') {
        const buttonText = (lang + "").toUpperCase();
        function handleClick(lang) {
            switch (lang) {
                case 'en': i18n.changeLanguage('en'); navigate('/en/home'); break;
                default: i18n.changeLanguage('de'); navigate('/'+lang+'/home'); break;
            }
            return undefined;
        }

        return <button className={"btn btn-language" + (i18n.resolvedLanguage === 'en' ? ' active' : '')}
                       onClick={handleClick('en')}
        >{buttonText}</button>
    }

    return <div className={isIFrame ? 'isiframe' : ''}>
        <div className="fs-container linear-color-change">
            {project.projectType === 'konventionell' ? <img src={startKonventionell} alt={t('app.header.image')}/> : null}
            {project.projectType === 'home' ? <img src={startHome} alt={t('app.header.image')}/> : null}
            {project.projectType === 'knx' ? <img src={startSmart} alt={t('app.header.image')}/> : null}
            {/*<img src={startImage} alt="Jung Konfigurator"/>*/}
            {/*<div className="bg-color-change"></div>*/}
            <div className="content-container">
                <div className="content">
                    {project.projectType === 'konventionell'
                        ? <>
                            <div className={"app-title"}>{t('app.title.konventionell')}</div>
                            <div className="section-title">{t('section.title.knx')}</div>
                        </>
                        : (project.projectType === 'home'
                            ? <>
                                <div className="app-title home">{t('app.title.home')}</div>
                                <div className="section-title home">{t('section.title.knx')}</div>
                            </>
                            : <>
                                <div className="app-title">{t('app.title.knx')}</div>
                                <div className="section-title">{t('section.title.knx')}</div>
                            </>)
                    }
                </div>
            </div>
        </div>
        {isdev ? <div className="fs-container mt-15 ptb-25">
            <div className="padding-container" style={{display:"flex", alignItems:"center"}}>
                <div className="section-title">{t('app.language')}</div>
                <div className="project-type" style={{display:"flex", alignItems:"center"}}>
                    <button className={"btn btn-language" + (i18n.resolvedLanguage === 'en' ? ' active' : '')} onClick={(e) => {e.preventDefault();i18n.changeLanguage('en');navigate('/en/home');initproject();return false;}}>EN</button>
                    <button className={"btn btn-language" + (i18n.resolvedLanguage === 'de' ? ' active' : '')} onClick={(e) => {e.preventDefault();i18n.changeLanguage('de');navigate('/de/home');initproject();return false;}}>DE</button>
                    <button className={"btn btn-language" + (i18n.resolvedLanguage === 'fr' ? ' active' : '')} onClick={(e) => {e.preventDefault();i18n.changeLanguage('fr');navigate('/fr/home');initproject();return false;}}>FR</button>
                    <button className={"btn btn-language" + (i18n.resolvedLanguage === 'nl' ? ' active' : '')} onClick={(e) => {e.preventDefault();i18n.changeLanguage('nl');navigate('/nl/home');initproject();return false;}}>NL</button>
                    <button className={"btn btn-language" + (i18n.resolvedLanguage === 'it' ? ' active' : '')} onClick={(e) => {e.preventDefault();i18n.changeLanguage('it');navigate('/it/home');initproject();return false;}}>IT</button>
                    <button className={"btn btn-language" + (i18n.resolvedLanguage === 'at' ? ' active' : '')} onClick={(e) => {e.preventDefault();i18n.changeLanguage('at');navigate('/at/home');initproject();return false;}}>AT</button>
                    <button className={"btn btn-language" + (i18n.resolvedLanguage === 'es' ? ' active' : '')} onClick={(e) => {e.preventDefault();i18n.changeLanguage('es');navigate('/es/home');initproject();return false;}}>ES</button>
                </div>
            </div>
        </div> : null}
        <div className={"project-infos"}>
            <div className="fs-container mt-15 ptb-25" style={{maxWidth: 'calc(50%-7.5px)', marginRight: '7.5px'}}>
                <div className="padding-container">
                    <div className="section-title">{t('project.info.title')}</div>
                    <input style={{padding: '0 5px'}} type="text" name="projectName" id="projectName" onBlur={onProjectNameBlur} onChange={ChangeProjectName} value={projectName} className="plan-code full-width mt-15"/>
                </div>
            </div>
            <div className="fs-container mt-15 ptb-25" style={{maxWidth: 'calc(50% - 7.5px', marginLeft: '7.5px'}}>
                <div className="padding-container">
                    <div className="section-title" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>{t('planing.code.title')}
                        <TooltipWithButton props={{title: '', message: t('planing.code.info'), noActive: true}} />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px', width: '100%'}}>
                        <input type="text" name="projectCode" className="plan-code" style={{flexGrow: '1'}}
                               onChange={e => setProjectCode(e.target.value?.toUpperCase())} value={projectCode}
                        />
                        <StartButton className="active" onClick={e => {loadProjectFromCode()}} style={{height: '24px', fontSize: '16px', padding: '16px 30px', marginLeft: '15px', borderRadius: '7px'}}>{t('planing.code.button')}</StartButton>
                    </div>
                </div>
            </div>
        </div>
        {project.projectType === 'konventionell'
            ? <>
                <div className={"fs-container mt-15 ptb-25"}>
                    <div className={"padding-container"}>
                        <div className={"section-title"}>{t('construction.type.konventionell.title')}</div>
                        <div className={"section-text"}>{t('construction.type.konventionell.text')}</div>
                        <div className={"construction-type"}>
                            {getButton4(t('construction.type.konventionell.button.new.text'), "neubau", "", t('construction.type.konventionell.button.new.info'))}
                            {getButton5(t('construction.type.konventionell.button.modification.text'), "umbau", "", t('construction.type.konventionell.button.modification.info'))}
                        </div>
                    </div>
                </div>
            </>
            : null
        }
        {project.projectType === 'home'
            ? <>
                <div className={"fs-container mt-15 ptb-25"}>
                    <div className={"padding-container"}>
                        <div className={"section-title"}>{t('construction.type.home.title')}</div>
                        <div className={"section-text"}>{t('construction.type.home.text')}</div>
                        <div className={"construction-type"}>
                            {getButton4(t('construction.type.home.button.new.text'), "neubau", "", t('construction.type.home.button.new.info'))}
                            {getButton5(t('construction.type.home.button.modification.text'), "umbau", "", t('construction.type.home.button.modification.info'))}
                        </div>
                    </div>
                </div>
            </>
            : null
        }
        <div className="fs-container mt-15 ptb-25">
            <div className="padding-container">
                <div className="section-title">{t('project.type.title')}</div>
                <div className="section-text">{t('project.type.description')}</div>
                <div className="project-type">
                    <ProjectSelectComponent selectedIx={project.selectedMusterIx} projects={projectSelectList} onSelectProject={setSelectedProject} projectType={project.projectType} subProjectType={project.subProjectType} />
                </div>
            </div>
        </div>
        <div className="fs-container mt-15 ptb-25">
            <div className="padding-container">
                <div className="section-title">{t('project.structure.title')}</div>
                <div className="section-text">{t('project.structure.description')}</div>
                <div className="project-equipments mt-15">
                    <div className="fs-box">
                        <div className="row stickyRow border-bottom mb-15 background-grau stickyTop126 phoneNoShow">
                            <div className="text-center col-5"> </div>
                            <div className={"text-center col-5 phone-columns"}>
                                <div className={"text-center col-2 w-188" + (currentColumn === 1 ? ' active' : ' hidden')}>
                                    <img src={iconTuer} alt="" style={{maxWidth: "100%", maxHeight: "100%", height: "42px"}}/>
                                    {['home'].includes(project.projectType)
                                        ? <TooltipWithButton props={{absolutePosition: true, noActive: true, title: '', message: t('project.structure.installationLocation.' + project.projectType + '.info')}} />
                                        : null}
                                    {['konventionell'].includes(project.projectType)
                                        ? <TooltipWithButton props={{absolutePosition: true, noActive: true, title: '', message: t('project.structure.installationLocation.' + project.projectType + '.info')}} />
                                        : null}
                                    {project.projectType === 'knx'
                                        ? <TooltipWithButton props={{absolutePosition: true, noActive: true, title: '', message: t('project.structure.installationLocation.' + project.projectType + '.info')}} />
                                        : null}
                                    <div className={"roomCounter"}>{getFloorTotal('doorCount')}</div>
                                    <div className="svgDescription" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>{t('project.structure.installationLocation')}
                                        </div>
                                    </div>
                                </div>
                                <div className={"text-center col-3 w-188" + (currentColumn === 2 ? ' active' : ' hidden')}>
                                    <img src={iconFenster} alt="" style={{maxWidth: "100%", maxHeight: "100%", height: "42px"}}/>
                                    {['nl'].includes(language)
                                        ? <TooltipWithButton props={{absolutePosition: true, noActive: true, title: '', message: t('project.structure.window.info')}} />
                                        : null}
                                    <div className={"roomCounter"}>{getFloorTotal('windowCount')}</div>
                                    <div className="svgDescription">{t('project.structure.window')}</div>
                                </div>
                                <div className={"text-center col-3 w-188" + (currentColumn === 3 ? ' active' : ' hidden')}>
                                    <img src={iconHeizung} alt="" style={{maxWidth: "100%", maxHeight: "100%", height: "42px"}}/>
                                    {['nl'].includes(language)
                                        ? <TooltipWithButton props={{absolutePosition: true, noActive: true, title: '', message: t('project.structure.radiator.info')}} />
                                        : null}
                                    <div className={"roomCounter"}>{getFloorTotal('heatingCount')}</div>
                                    <div className="svgDescription">{t('project.structure.radiator')}</div>
                                </div>
                                <div className={"text-center col-3 w-188" + (currentColumn === 4 ? ' active' : ' hidden')}>
                                <img src={iconFussbodenHeizung} alt="" style={{maxWidth: "100%", maxHeight: "100%", height: "42px"}}/>
                                {['nl'].includes(language)
                                    ? <TooltipWithButton props={{absolutePosition: true, noActive: true, title: '', message: t('project.structure.underfloorHeating.info')}} />
                                    : null}
                                <div className={"roomCounter"}>{getFloorTotal('floorheatingCount')}</div>
                                <div className="svgDescription">{t('project.structure.underfloorHeating')}</div>
                            </div>
                                <a style={{color: "inherit"}} href={"/"} onClick={(event) => {event.preventDefault(); event.stopPropagation(); setNextColumn(); return false;}} className={"next-button"}>
                                    <ArrowForwardIcon />
                                </a>
                            </div>
                        </div>
                        <div className={"customOpener phoneShow" + (customOpened ? ' opened' : '')}>
                            <a href="/" onClick={(event) => {event.preventDefault(); setCustomOpened(!customOpened); return false;} }>
                                <FilterIcon />
                                <div>{customOpened ? t('phone.button.close') : t('phone.button.edit')}</div>
                            </a>
                        </div>
                        <div className="row stickyRow mb-15 background-grau stickyTop126 phoneShow">
                            {customOpened ? <>
                                    <div className="text-center col-5"></div>
                                    <div className={"text-center col-5 phone-columns"}>
                                        <GetPlanColumns />
                                    </div>
                                </>
                                : <>
                                    <div className={"phone-row"}>
                                        <GetPlanColumns woArrow={true} />
                                    </div>
                                </>
                            }
                        </div>
                            <div className={"container" + (!customOpened ? " phoneNoShow" : "")}>
                                <div className={"floorList" + (dragActive ? " dragActive" : "")}>
                                    <ReactSortable
                                        list={project.floors}
                                        setList={l => setFloors(l)}
                                        group={"floorLists"}
                                        handle={".dragHandle"}
                                        onStart={onStart}
                                        onEnd={onEnd}
                                    >
                                        {project.floors.map((f, idx) => <FloorComponent floor={f} key={f.id} project={project}
                                                                                        updateFloor={updateFloor}
                                                                                        deleteFloor={deleteFloor}
                                                                                        schalterdesign={schalterdesign}
                                                                                        opened={idx === 0} withCounter={true}
                                                                                        showMouseOver={true}
                                                                                        tasterCursor={1}
                                                                                        column={currentColumn}
                                                                                        jumpTo={jumpTo}
                                        />)}
                                    </ReactSortable>
                                </div>
                                <AddFloorComponent onAddFloor={onAddFloor}/>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
