import React from "react";
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import RoomComponent from "./RoomComponent";
import AddRoomComponent from "./AddRoomComponent";
import {ReactSortable} from "react-sortablejs";
import LabelBox from "./LabelBox/LabelBox";
import {GenerateRoomToAddService} from "../services/GenerateRoomToAddService";
import CountBox from "./CountBox/CountBox";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
// import uarr from "../images/arrow_up.svg";
import uarrh from "../images/arrow_up_hover.svg";
// import darr from "../images/arrow_down.svg";
import darrh from "../images/arrow_down_hover.svg";
import availableRooms_de from "./../data/availableRooms.json"
import availableRooms_at from "./../data/availableRooms_at.json"
import availableRooms_en from "./../data/availableRooms_en.json"
import availableRooms_es from "./../data/availableRooms_es.json"
import availableRooms_fr from "./../data/availableRooms_fr.json"
import availableRooms_it from "./../data/availableRooms_it.json"
import availableRooms_nl from "./../data/availableRooms_nl.json"
import {useTranslation} from "react-i18next";

export default function FloorComponent({project, floor, updateFloor, deleteFloor, schalterdesign, opened, withCounter = false, showMouseOver = false, tasterCursor = null, column = 1, jumpTo}){
    const {t, i18n} = useTranslation();
    const language = i18n.resolvedLanguage;
    const [open, setOpen] = React.useState(opened !== undefined ? opened : true);
    let floorValues = { doorCount: 0, windowCount: 0, heatingCount: 0, floorheatingCount: 0};

    function onOpenClose(e){
        if (e) { e.preventDefault(); e.stopPropagation(); }
        setOpen(!open);
        if ('parentIFrame' in window && e) {
            jumpTo(e.pageY);
        }
        if (e) { return false; }
    }
    function rename(name){
        updateFloor({...floor, name: name});
    }
    function updateRoom(room){
        updateFloor({...floor, rooms: floor.rooms.map(r => r.id !== room.id ? r : room), floorValues: floorValues});
    }
    // function updateFloorValues(room, typ = 'add') {
    //     Object.keys(floorValues).forEach(key => {
    //         floorValues = {
    //             ...floorValues,
    //             [key]: typ === 'add' ? floorValues[key] + room[key] : floorValues[key] - room[key]
    //         };
    //     });
    // }
    function addRoom(room){
        GenerateRoomToAddService.resetDefaultDesignConfig();
        const newRoom = GenerateRoomToAddService.generate({roomIn: room, selectedLevel: project.selectedLevel, schalterdesign: schalterdesign, subProjectType: project.subProjectType});
        // updateFloorValues(newRoom);
        updateFloor({ ...floor, rooms: [...floor.rooms, newRoom], floorValues: floorValues });
        if (!open) { onOpenClose(); }
    }
    function setRooms(rooms){
        updateFloor({...floor, rooms: rooms});
    }
    function deleteRoom(room){
        // updateFloorValues(room, 'remove');
        updateFloor({...floor, rooms: floor.rooms.filter(r => r.id !== room.id), floorValues: floorValues});
    }
    function onDelete(e){
        deleteFloor(floor);
    }

    const [dragActive, setDragActive] = React.useState(false);
    function onStart(){
        setDragActive(true)
    }
    function onEnd(){
        setDragActive(false)
    }
    function setValueForRooms(floor, cat, v) {
        v = parseInt(v, 10);
        const oldValue = {...floorValues};
        floorValues[cat] = v;
        floor = {
            ...floor,
            rooms: floor.rooms.map(r => {
                r[cat] = v;
                let availableRoom;
                switch (cat) {
                    case 'doorCount':
                        if (oldValue[cat] < 1 && v > 0) {
                            switch (language) {
                                case 'de': availableRoom = availableRooms_de.filter(i => i.typePro === r.typePro)[0]; break;
                                case 'at': availableRoom = availableRooms_at.filter(i => i.typePro === r.typePro)[0]; break;
                                case 'en': availableRoom = availableRooms_en.filter(i => i.typePro === r.typePro)[0]; break;
                                case 'es': availableRoom = availableRooms_es.filter(i => i.typePro === r.typePro)[0]; break;
                                case 'fr': availableRoom = availableRooms_fr.filter(i => i.typePro === r.typePro)[0]; break;
                                case 'it': availableRoom = availableRooms_it.filter(i => i.typePro === r.typePro)[0]; break;
                                case 'nl': availableRoom = availableRooms_nl.filter(i => i.typePro === r.typePro)[0]; break;
                            }
                            if (availableRoom && availableRoom.defaultEquipment && availableRoom.defaultEquipment[project.selectedLevel-1] && availableRoom.defaultEquipment[project.selectedLevel-1].values) {
                                r = {...r, selectedEquipment: {...r.selectedEquipment, 'schaltbare_beleuchtung': availableRoom.defaultEquipment[project.selectedLevel-1].values.schaltbare_beleuchtung}};
                                r = {...r, selectedEquipment: {...r.selectedEquipment, 'dimmbare_beleuchtung': availableRoom.defaultEquipment[project.selectedLevel-1].values.dimmbare_beleuchtung}};
                            }
                        } else if (v < 1) {
                            r = {...r, selectedEquipment: {...r.selectedEquipment, 'schaltbare_beleuchtung': 0}};
                            r = {...r, selectedEquipment: {...r.selectedEquipment, 'dimmbare_beleuchtung': 0}};
                        }
                        break;
                    case 'windowCount': r.selectedEquipment.verschattung_steuern = v > 0 ? r.selectedEquipment.verschattung_steuern : 0; break;
                    case 'heatingCount': r.selectedEquipment.heizen_heizkoerper = v > 0 ? r.selectedEquipment.heizen_heizkoerper : 0; break;
                    case 'floorheatingCount': r.selectedEquipment.heizen_fußbodenheizung = v > 0 ? r.selectedEquipment.heizen_fußbodenheizung : 0; break;
                    default: break;
                }
                // updateRoom(r);
                return r;
            })
        };
        updateFloor({...floor, floorValues: floorValues});
        // console.log('floorvalues', {...floor})
    }
    if (floor.floorValues) {
        floorValues = Object.assign(floorValues, floor.floorValues);
        // console.log('floorValues asigned', floorValues)
    }
    const CircleButton = withStyles((theme) => ({
        root: {
            cursor: "pointer",
            borderRadius: "22px",
            width: "22px",
            height: "22px",
            textAlign: "center",
            border: "none",
            minWidth: "unset",
            overflow: "hidden",
            padding: "0 0 0 4px",
            margin: "0 7px 0 2px"
        },
    }))(Button);

    return <div className="floorWrapper">
        <div className="floorBoxHeader" style={{display: withCounter ? 'flex' : null, alignItems: withCounter ? 'center' : null}}>
            <div className={"phone-w-50"}>
                <div className="openClose" onClick={(e) => onOpenClose(e)}>
                    {open ? <CircleButton>
                        <img className="icon-to-page-bottom" src={uarrh} alt={""} width="25" style={{marginRight: "-22px"}} />
                        <img className="icon-to-page-bottom only-hover" src={uarrh} alt={""} width="25"/>
                    </CircleButton> : <CircleButton>
                        <img className="icon-to-page-bottom" src={darrh} alt={""} width="25" style={{marginRight: "-22px"}} />
                        <img className="icon-to-page-bottom only-hover" src={darrh} alt={""} width="25"/>
                    </CircleButton> }
                </div>
                <div style={{flex: '5 1'}}>
                    <LabelBox value={floor.name} setValue={rename} deleteItem={onDelete} isBold={true}/>
                </div>
                {withCounter ? <div className={"text-center col-1 float-right"} style={{flex: '1 1', marginLeft: '-8.5%'}}></div> : null}
            </div>
            <div className={"phone-w-50"}>
                {withCounter ? <div className={"text-center col-3 float-right w-188" + (column === 1 ? ' active' : ' hidden')}><CountBox minValue={0} maxValue={5} value={floorValues.doorCount} setValue={value => setValueForRooms(floor, 'doorCount', value)} jumpTo={jumpTo}/></div> : null}
                {withCounter ? <div className={"text-center col-3 float-right w-188" + (column === 2 ? ' active' : ' hidden')}><CountBox minValue={0} maxValue={99} value={floorValues.windowCount} setValue={value => setValueForRooms(floor, 'windowCount', value)} jumpTo={jumpTo}/></div> : null}
                {withCounter ? <div className={"text-center col-3 float-right w-188" + (column === 3 ? ' active' : ' hidden')}><CountBox minValue={0} maxValue={99} value={floorValues.heatingCount} setValue={value => setValueForRooms(floor, 'heatingCount', value)} jumpTo={jumpTo}/></div> : null}
                {withCounter ? <div className={"text-center col-3 float-right w-188" + (column === 4 ? ' active' : ' hidden')}><CountBox minValue={0} maxValue={99} value={floorValues.floorheatingCount} setValue={value => setValueForRooms(floor, 'floorheatingCount', value)} jumpTo={jumpTo}/></div> : null}
            </div>
        </div>
        {open || floor.rooms.length < 1 ? (
            <div className={"roomList" + (dragActive ? " dragActive" : "")} >
                <ReactSortable
                    list={floor.rooms}
                    setList={l => setRooms(l, floor)}
                    group={"roomLists"}
                    handle={".dragHandle"}
                    onStart={onStart}
                    onEnd={onEnd}
                >
                    {floor.rooms.map(
                        r => <RoomComponent room={r} key={r.id} updateRoom={updateRoom} deleteRoom={deleteRoom} showMouseOver={showMouseOver} col={column} tasterCursor={tasterCursor} jumpTo={jumpTo} project={project}/>
                    )}
                </ReactSortable>
                {!floor.rooms.length ? <>
                    <div className="roomRow row">
                        <div className="col-5 contextBoxHover" style={{height: '41px'}}></div>
                    </div>
                </> : null}
                <AddRoomComponent onAddRoom={addRoom} floor={floor}/>
            </div>
        ) : null }
    </div>
}
