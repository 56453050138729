import React, {useEffect} from "react";

export default function StepVerifyComponent({project, projectSelectList, setSelectedProject, updateProject, schalterdesign, startProject, setProjectCode, projectCode, loadProjectFromCode, trackingObj, isIFrame, jumpTo, setPrCode}){

    const [designs, setDesigns] = React.useState([]);
    const [austattungs, setAustattungs] = React.useState([]);
    const [isHome, setIsHome] = React.useState(false);
    let resHtml = '';

    function handleOnChangeDesign(e) {
        // console.log('onchange Design', e)
        let reader = new FileReader();
        reader.onload = onReaderLoadDesign;
        if (e.target.files[0] && e.target.files[0].name.indexOf('home') >= 0) { setIsHome(true); }
        reader.readAsText(e.target.files[0]);
    }
    function onReaderLoadDesign(e){
        // console.log(e.target.result);
        let obj = JSON.parse(e.target.result);
        alert_data_design(obj);
    }
    function alert_data_design(obj){
        // console.log('alert_data_design', obj)
        obj.forEach(item => {
            // console.log('parsing item', item)
            const serie = item.id;
            let colorId = '';
            let rahmenCid = '';
            item.operatingConcepts.forEach(oc => {
                oc.colorsTaster.forEach(ct => {
                    colorId = ct.colorId;
                    ct.colorsRahmen.forEach(cr => {
                        rahmenCid = cr.colorId;
                        // designs[serie + '|' + colorId + '|' + rahmenCid] = { serie, colorId, rahmenCid };
                        designs.push({ serie, colorId, rahmenCid });
                        setDesigns(designs);
                        // console.log('pushed design { ', serie, colorId, rahmenCid, ' }')
                    });
                });
            });
        });
        // console.log('designs', designs)
    }
    function handleOnChangeAusstattung(e) {
        // console.log('onchange Ausstattung', e)
        let reader = new FileReader();
        reader.onload = onReaderLoadAusstattung;
        if (e.target.files[0] && e.target.files[0].name.indexOf('home') >= 0) { setIsHome(true); }
        reader.readAsText(e.target.files[0]);
    }
    function onReaderLoadAusstattung(e){
        // console.log(e.target.result);
        let obj = JSON.parse(e.target.result);
        alert_data_austattung(obj);
    }
    function alert_data_austattung(obj){
        // console.log('alert_data_austattung', obj)
        obj.forEach(item => {
            // console.log('parsing item', item)
            const serie = item.serie;
            const colorId = isHome
                ? (['JUNG-LC_home', 'JUNG-A-Cube_home', 'JUNG-A-Viva_home', 'JUNG-Aflow_home'].includes(serie) ? item.wippe : item["scha-aufsatz-2"])
                : item.wippe;
            const rahmenCid = item.rahmen;
            // austattungs[serie + '|' + colorId + '|' + rahmenCid] = { serie, colorId, rahmenCid };
            austattungs.push({ serie, colorId, rahmenCid, cField: ['JUNG-LC_home', 'JUNG-A-Cube_home', 'JUNG-A-Viva_home', 'JUNG-Aflow_home'].includes(serie) ? 'wippe' : 'scha-aufsatz-2' });
            setAustattungs(austattungs);
            // console.log('pushed austattung { ', serie, colorId, rahmenCid, ' }')
        });
        // console.log('ausstattungs', austattungs, 'designs', designs)
    }
    function handleVerifyDesignWithAusstattung(e) {
        // console.log('handleVerifyDesignWithAusstattung', e, austattungs.length, designs.length, '=', !austattungs.length || !designs.length)
        let res = document.getElementById('result');
        // console.log('result', res, res.innerHTML)
        setTimeout(() => {startCompare(res);}, 500);
    }

    function startCompare(res) {
        // console.log('startcompare', res, designs, austattungs)
        resHtml = '';
        designs.forEach(dValue => {
            const key = dValue.serie + '|' + dValue.colorId + '|' + dValue.rahmenCid;
            const found = austattungs.filter(i => i.serie === dValue.serie && i.colorId === dValue.colorId && i.rahmenCid === dValue.rahmenCid)[0];
            if (!found) {
                // console.log('parsing dValue', key, found)
                appendRow('<div>Design Serie: '+ dValue.serie + ' | ColorId: ' + dValue.colorId + ' | Rahmen: ' + dValue.rahmenCid + ' <= nicht gefunden!</div>');
            } else {
                // console.log('parsing dValue', key, false)
            }
        });
        austattungs.forEach(aValue => {
            const key = aValue.serie + '|' + aValue.colorId + '|' + aValue.rahmenCid;
            const found = designs.filter(i => i.serie === aValue.serie && i.colorId === aValue.colorId && i.rahmenCid === aValue.rahmenCid);
            if (!found.length) {
                // console.log('parsing aValue', key, false)
                appendRow('<div>Austattung Serie: ' + aValue.serie + ' | ColorId: ' + aValue.colorId + ' | Rahmen: ' + aValue.rahmenCid + ' <= nicht gefunden!' + (isHome ? ('ColorId Schlüssel: ' + aValue.cField) : '') + '</div>');
            }
        });
        res.innerHTML = resHtml;
        if (resHtml === '') {
            res.innerHTML = 'Schlüsselprüfung erfolgreich !';
        }
    }
    function appendRow(data) {
        resHtml += '<br/>' + data;
        // console.log('resHtml is now', resHtml)
    }

    return <div>
        <form style={{padding: '15px', width: '100%'}}>
            <div style={{marginBottom: '10px', display: 'flex', width: '100%'}}>
                <label style={{minWidth: '250px'}}>Schalterdesign JSON</label>
                <input type={"file"} name={"design"} onChange={handleOnChangeDesign} />
            </div>
            <div style={{marginBottom: '10px', display: 'flex', width: '100%'}}>
                <label style={{minWidth: '250px'}}>Ausstattung JSON</label>
                <input type={"file"} name={"design"} onChange={handleOnChangeAusstattung} />
            </div>
            <div className={"form-row"}>
                <button type={"button"} onClick={handleVerifyDesignWithAusstattung}>Prüfen</button>
            </div>
        </form>
        <div id="result"></div>
    </div>;
}
